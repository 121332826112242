import React, { Component } from 'react';

//  Icon Imports
import ArrowIcon from '../../icons/ArrowIcon';

class Quote extends Component {
    state = {
        index: 0,
        newIndex: 0,
        fade: true,
        direction: null
    }

    handleChangeQuote = (direction, e) => {
        if(this.state.index === this.props.quotes.length - 1 && direction) return false;
        if(this.state.index === 0 && !direction) return false;
        return this.setState({fade: false, direction});
    }

    changeQuote = (index, e) => {
        if(index === this.state.index) return null;
        return this.setState({fade: false,newIndex: index});
    }

    componentDidMount = () => {
        const quote = this.refs.quote;
        quote.addEventListener('animationend', e => {
            if(e.animationName === 'fadeOut') {
                if(this.state.direction === null) {
                    return this.setState({index: this.state.newIndex, fade: true, direction: null});
                } else if(this.state.direction) {
                    return this.setState({index: this.state.index + 1, fade: true, direction: null});
                } else {
                    return this.setState({index: this.state.index - 1, fade: true, direction: null});
                }
            }
        });
    }

    render() {
        return (
            <div className="quote">
                <ArrowIcon onclickHandler={this.handleChangeQuote} data={false}/>
                <div ref="quote" className={this.state.fade ? 'fadeTextIn' : 'fadeTextOut'}>
                    <h4>{this.props.quotes[this.state.index].quote}</h4>
                    <ul className="quoted">
                        <li><span>-</span>{this.props.quotes[this.state.index].name}</li>
                        <li>{this.props.quotes[this.state.index].position}</li>
                        <li>{this.props.quotes[this.state.index].company}</li>
                    </ul>
                </div>
                <ArrowIcon onclickHandler={this.handleChangeQuote} data={true}/>
                <div className="selection">
                    {this.props.quotes.map((quote, index) => (
                        <svg key={index} data-index={index} onClick={e => this.changeQuote(index, e)} className="circle">
                            <circle className={this.state.index === index ? 'active' : undefined} cx="50%" cy="50%" r="5"/>
                        </svg> 
                    ))}
                </div>
            </div>
        );
    }
};

export default Quote;