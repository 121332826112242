import React from 'react';

//  Icon Imports
import XIcon from '../../icons/XIcon';

//  Style Imports
import '../../styles/css/modules/lightbox.css'

const Lightbox = props => (
    <div className="lightbox fadeTextIn" onClick={props.closeLightbox}>
        <div>
            <XIcon onclickHandler={props.closeLightbox}/>
            <div className="fluidMedia ">
                <iframe src={props.videoUrl} title="Video" frameBorder="0"></iframe>
            </div>
            <div className="detials">
                <h3>{props.title}</h3>
                {props.client && <p>Client: {props.client}</p>}
            </div>
        </div>
    </div>
);

export default Lightbox;