import React, { Component } from 'react';

//  App Component
import LightBox from './util/Lightbox';

//  Data Import
import data from '../data/videos';

//  Style Imports
import '../styles/css/pages/work-samples.css';

class WorkSamples extends Component {
    _isMounted = false;
    state = {
        index: [],
        scroll: 0,
        display: false,
        url: 'https://player.vimeo.com/video/229753118'
    }
    
    handleOnHover = e => {
        if(e.target.dataset.index && window.innerWidth >= 1200) {
            return this.setState({index: [Number(e.target.dataset.index)]});
        }
    }

    handleOnclick = e => {
        if(e.target.dataset.index) {
            return this.setState({
                display: true,
                url: data[e.target.dataset.index].url,
                name: data[e.target.dataset.index].name,
                client: data[e.target.dataset.index].client
            });
        }
    }

    closeLightbox = e => {
        if(e.target.nodeName === 'H3' || e.target.nodeName === 'P') return;
        return this.setState({display: false});
    }

    handleScroll = e => {
        if(!this._isMounted || window.innerWidth > 451) return null;
        if(this.state.scroll > window.scrollY - 50 && this.state.scroll < window.scrollY + 50) return null;
        const videoInfo = document.querySelectorAll(".info");
        const visible = [];
        for(let a = 0; a < videoInfo.length; a++) {
            if(videoInfo[a].offsetTop < window.scrollY + 500 && videoInfo[a].offsetTop >= window.scrollY) {
                visible.push(Number(videoInfo[a].dataset.index));
            }
        }
        if(Math.ceil(window.scrollY) > window.scrollMaxY - 100) visible.push(Number(videoInfo[videoInfo.length - 1].dataset.index));
        if(this.state.index[0] === visible[0] && this.state.index[this.state.index.length - 1] === visible[visible.length - 1]) return null;
        return this.setState({
            index: [...visible],
            scroll: window.scrollY
        });
    }

    componentDidMount = () => {
        if(!this._isMounted) this._isMounted = true;
        document.title = "Harper Media - Work Samples";
        if(window.innerWidth < 451 && this._isMounted) {
            window.addEventListener('scroll', this.handleScroll);
            return this.setState({index: [0,1]});
        }
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        return (
            <main className="work-samples">
                <h1>Our Work</h1>
                <div className="container">
                    {data.map((video, index) => (
                        <div key={index} className="info" data-index={index}  onMouseEnter={this.handleOnHover} onClick={this.handleOnclick}>
                            <img 
                                key={index} 
                                srcSet={`
                                    ${require(`../images/thumbnails/desktop/${video.thumbnail}`)} 600w,
                                    ${require(`../images/thumbnails/tablet/${video.thumbnail}`)} 500w,
                                    ${require(`../images/thumbnails/mobile/${video.thumbnail}`)} 300w`}
                                sizes='
                                    (min-width: 1200px) 600px,
                                    (min-width: 451px) and (max-width: 1299px) 500px,
                                    (max-width: 450px) 300px'
                                alt={`${video.name} Thumbnail`}
                            />
                            <div className={this.state.index.indexOf(index) !== -1 ? "fadeTextIn" : null} >
                                <h3 data-index={index} onClick={this.handleOnclick}>{video.name}</h3>
                                <p>{video.client}</p>
                            </div>
                            
                        </div>
                    ))}
                </div>
                {this.state.display && <LightBox 
                    videoUrl={this.state.url} 
                    title={this.state.name}
                    client={this.state.client}
                    closeLightbox={this.closeLightbox}

                />}
            </main>
        )
    }
}

export default WorkSamples;