const videos = [
    {
        name: 'Disney\u00A0CF\u00A0Video',
        client: 'Walt Disney Company',
        url: 'https://player.vimeo.com/video/229753118',
        thumbnail: 'disney_cf.jpg'
    }, {
        name: 'Home Depot Commercial',
        client: 'Home Depot',
        url: 'https://player.vimeo.com/video/316900614',
        thumbnail: 'home_depot.jpg'
    }, {
        name: 'Disney IBIF Promo',
        client: 'Walt Disney Company',
        url: 'https://player.vimeo.com/video/229754944',
        thumbnail: 'disney_ibif.jpg'
    }, {
        name: 'Whole Foods Market Team Orientation',
        client: 'Whole Foods',
        url: 'https://player.vimeo.com/video/229758612',
        thumbnail: 'whole_foods.jpg'
    }, {
        name: 'Law Firm Promo',
        client: '',
        url: 'https://player.vimeo.com/video/241556985',
        thumbnail: 'law_firm.jpg'
    }, {
        name: 'Google Home - Lifestyle Video',
        client: 'Google',
        url: 'https://player.vimeo.com/video/316886580',
        thumbnail: 'google_home.jpg'
    }, {
        name: 'Publix Promotion',
        client: 'Publix',
        url: 'https://player.vimeo.com/video/326169657',
        thumbnail: 'publix.jpg'
    }, {
        name: 'Nickelodeon Network Promo',
        client: 'Nickelodeon',
        url: 'https://player.vimeo.com/video/316900543',
        thumbnail: 'nickdelodeon.jpg'
    }, {
        name: 'Canadian\u00A0Conservation Institute Promo',
        client: 'Canadian Conservation',
        url: 'https://player.vimeo.com/video/229756436',
        thumbnail: 'canadian_promo.jpg'
    }, {
        name: 'Subway Commercial',
        client: 'Subway',
        url: 'https://player.vimeo.com/video/316900632',
        thumbnail: 'subway.jpg'
    }, {
        name: "Romano's Macaroni Grill Commercial",
        client: "Romano's Macaroni Grill",
        url: 'https://player.vimeo.com/video/178073914',
        thumbnail: 'romanos_macaroni_grill.jpg'
    }, {
        name: 'Quiznos - Give Eating a Chance Commercial',
        client: 'Quiznos',
        url: 'https://player.vimeo.com/video/317587537',
        thumbnail: 'quizons_eating.jpg'
    }
];

export default videos;
