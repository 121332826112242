import React, { Component } from 'react';

//  Data Import
import data from '../data/staff';

//  Style Imports
import '../styles/css/pages/staff-page.css';

class Staff extends Component {
    componentDidMount = () => {
        document.title = "Harper Media - About";
    }
    render() {
        return (
            <main className="staff-page">
                <div className='content-block-with-banner'>
                    <div className='banner'>
                        <img 
                            srcSet={`
                                ${require(`../images/fullscreens/thin_banner2-desktop.jpg`)} 1501w,
                                ${require(`../images/fullscreens/thin_banner2-tablet.jpg`)} 800w,
                                ${require(`../images/fullscreens/thin_banner2-mobile.jpg`)} 448w`}
                            sizes='
                                (min-width: 1200px) 1500px,
                                (min-width: 451px) and (max-width: 1299px) 800px,
                                (max-width: 450px) 448px' 
                            alt='Banner'
                        />
                        <h3>- Who We Are -</h3>
                        <h4>Customized | Collaborative | Quality</h4>
                    </div>
                    <p id="title">We Believe...</p>
                    <p>…in the power of <span>EXCEPTIONAL VISUAL CONTENT</span>.  We know how it works, we know how to create it, and we know how to apply it to your needs</p>
                    <p>…that often times, your own core team – or internal agency – has the <span>VERY BEST IDEAS</span> of all, because you know exactly what you need. We work <span>WITH YOU</span> to discover the best course of action <span>TOGETHER</span></p>
                    <p>…that our clients deserve <span>CUSTOMIZED EXPERTISE</span>.  You know what you want, we will provide exactly what you need – nothing more, nothing less</p>
                    <p>…that we can offer <span>BRAND STRATEGIES, CREATIVE SOLUTIONS, WORLD-CLASS PRODUCTION</span> and <span>MEDIA PLANNING</span> which will consistently <span>EXCEED YOUR EXPECTATIONS</span></p>
                    {window.innerWidth > 1199 && <div className='banner desktop'>
                        <img 
                            srcSet={`
                                ${require(`../images/fullscreens/thin_banner-desktop.jpg`)} 1501w,
                                ${require(`../images/fullscreens/thin_banner-tablet.jpg`)} 800w,
                                ${require(`../images/fullscreens/thin_banner-mobile.jpg`)} 448w`}
                            sizes='
                                (min-width: 1200px) 1500px,
                                (min-width: 451px) and (max-width: 1299px) 800px,
                                (max-width: 450px) 448px' 
                            alt='Banner'
                        />
                        <h3>- Our Team -</h3>
                        <h4>Creative | Flexible | Reliable</h4>
                    </div>}
                </div>
                <div className='staff'>
                    {data.map((member, index) => {
                        //const view = 
                        const name = member.name.replace(/ /g, '_').toLowerCase();
                        return <React.Fragment>
                            <div key={index} className="container">
                                {index % 2 === 0 && window.innerWidth > 1199 ? [
                                    <img 
                                        key={index}
                                        className='desktop'
                                        srcSet={`${require(`../images/staff/${name}_desktop.jpg`)} 640w`}
                                        sizes='(min-width: 1199px)'
                                        alt={member.name}
                                    />,
                                    <div key={index + 100}  className="vertical-line desktop"></div>
                                ] : null}
                                <div>
                                    {window.innerWidth < 1200 && <img 
                                        className='not-desktop'
                                        srcSet={`
                                        ${require(`../images/staff/${name}_tablet.jpg`)} 499w,
                                        ${require(`../images/staff/${name}_mobile.jpg`)} 250w`}
                                        sizes='
                                            (min-width: 451px) and (max-width: 1299px) 400px,
                                            (max-width: 450px) 200px'
                                        alt={member.name}
                                    />}
                                    <h3>{member.name}</h3>
                                    <h4>{member.title}</h4>
                                    <p>{member.description}</p>
                                </div>
                                {Math.abs(index % 2) === 1 && window.innerWidth > 1199 ? [
                                    <div key={index + 100} className="vertical-line desktop"></div>,
                                    <img 
                                        key={index}
                                        className='desktop'
                                        srcSet={`${require(`../images/staff/${name}_desktop.jpg`)} 640w`}
                                        sizes='(min-width: 1199px)'
                                        alt={member.name}
                                    />,
                                ] : null}
                            </div>
                            {index !== data.length - 1 && <span key={index + 100} className="hr"></span>}
                            </React.Fragment>})}
                </div>
            </main>
        );
    }
}

export default Staff;