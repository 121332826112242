const homePageData = {
    banners: [
        {
            quote: '"Hard working, detailed and creative - Harper Media brings their clients all of the things that they need for success…”',
            name: 'Niels Schuurmans',
            company: 'CMO/CCO Paramount Network/CMT/TV LAND'
        }, {
            quote: '“...He’s an intellectual guy with an artistic soul and has the ability to create a work environment that is both innovative and approachable.”',
            name: 'Jessica Holmes',
            company: 'KTLA 5 News - Los Angeles',
            position: 'News Anchor '
        }, {
            quote: '“Jason is a hard working, multi-tasking producer/director. He did 7 seasons of the hardest show on television - and didn’t age half as much as me.”',
            name: 'Richard Barry',
            position: 'VP, Creative Director',
            company: 'Viacom International Media Networks'
        }, {
            quote: '"He fundamentaly understands our goals and is able to connect with our customers like no one else."',
            name: 'Ryan McCabe',
            position: 'Digital Marketing Strategist',
            company: 'The Villages'
        }, {
            quote: '“Jason Harper is without question one of the most talented writer/director/producers I have ever worked with.',
            name: 'Dave Aizer',
            position: 'TV Host, Writer, Producer'
        }, {
            quote: `"...His expertise, wisdom and experienced eye have helped us produce those results."`,
            name: 'Ryan Jennings',
            position: 'Director, Mass Media & Catalog Marketing'
        }, {
            quote: `"Jason can comfortable and aptly slide from field producer, to post producer, to director (and even camera), and do all of them at an extremely high level."`,
            name: 'Keith Allo',
            position: 'VP Programming & Original Productions'
        }
    ],
    productions: [
        {
            alt: 'Disney IBIF Promo',
            name: 'Disney IBIF Promo',
            client: 'Walt Disney Company',
            url: 'https://player.vimeo.com/video/229754944'
        }, {
            alt: 'Featured Production Google',
            name: 'Google Home',
            client: 'Google',
            url: 'https://player.vimeo.com/video/316886580'
        }, {
            alt: 'Disney CF',
            name: 'Disney CF',
            client: 'Walt Disney Company',
            url: 'https://player.vimeo.com/video/229753118'
        }

    ]
}

export default homePageData