import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from "react-cookie";
import {BrowserRouter as Router} from 'react-router-dom';

//  App Styles
import './styles/css/base.css';

ReactDOM.render(
    <Router>
        <CookiesProvider>
            <App/>
        </CookiesProvider>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
