import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

//  Header Components
import Logo from '../icons/Logo';

//  Icon Imports
import ClosedMenu from '../icons/ClosedMenu';
import XIcon from '../icons/XIcon';

class Header extends Component {
    openMenu = e => {
        document.querySelector('nav').classList.remove('close-menu');
        document.querySelector('nav').classList.add('open-menu');
    }

    closeMenu = e => {
        document.querySelector('nav').classList.remove('open-menu');
        document.querySelector('nav').classList.add('close-menu');
    }

    render() {
        return (
            <header>
                <NavLink exact to="/"><Logo /></NavLink>
                <ClosedMenu classes="mobile" onclickHandler={this.openMenu}/>
                <nav className={this.props.location.pathname === '/home' ? 'home-nav' : null}>
                    <div className="desktop"></div>
                    <XIcon classes="mobile" onclickHandler={this.closeMenu}/>
                    <NavLink exact to="/home" onClick={this.closeMenu}>HOME</NavLink>
                    <NavLink exact to="/about" className="drop-down" onClick={this.closeMenu}>ABOUT</NavLink>
                    <NavLink exact to="/what-we-do" className="drop-down" onClick={this.closeMenu}>WHAT&nbsp;WE&nbsp;DO </NavLink>
                    <NavLink exact to="/why-harper-media" onClick={this.closeMenu}>WHY&nbsp;HARPER&nbsp;MEDIA?</NavLink>
                    <NavLink exact to="/our-work" onClick={this.closeMenu}>OUR&nbsp;WORK</NavLink>
                    <NavLink exact to="/contact" onClick={this.closeMenu}>CONTACT</NavLink>
                    <div className="desktop"></div>
                </nav>
            </header>
        )
    }
}

export default Header;