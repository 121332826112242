const staff = [
    {
        name: 'JASON HARPER',
        title: 'Executive Producer / Writer',
        description: `Jason is an Emmy award-winning commercial and broadcast television Writer / Producer / Director with a proven strength in creating captivating and inspirational content.  For more than 25 years Jason has helped to create a variety of entertaining programming such as the #1 rated children’s show in television history, "Slimetime Live" (Nickelodeon), "The Biggest Loser" (NBC), “Hogan Knows Best” (VH1), “The Match Off” (NBC), “Rowan Atkinson Live” (BBC), “Big Man On Campus” (CW), "How'd That Get On My Plate?" (Food Network), "The Kids are In Charge" (Travel Channel), "Be Discovered" (Disney), “Big Break” (NBC), “Feherty” (NBC), “Tailgate Warriors with Guy Fieri” (Food Network), “Open House” (NBC) and, "Food Tech", a part of the Modern Marvels series (History Channel).  He has worked as a Creative Consultant for Disney, NBCUniversal and A&E Networks. Much of Jason's current work focuses on brand marketing strategies, advertising and live entertainment.`
    }, {
        name: 'TROY SCHMIDT',
        title: 'Creative Director, Writer/Producer',
        description: `Troy began writing for TV in Los Angeles for such animated series as Dennis the Menace, Heathcliff, Flintstone Kids. In 1992, he moved to Orlando to write for The Mickey Mouse Club, on staff for three seasons, writing for the likes of Justin Timerberlake, Ryan Gosling, Britney Spears, Keri Russell and Christina Aguilera. He wrote for three Disney holiday parades on ABC, a Disney Animal Kingdom show and for the Hard Rock Café website as the Managing Content Editor. Troy was also a Writer/Producer for the GSN game show The American Bible Challenge starring Jeff Foxworthy, working on the pilot and all three seasons. Troy has written five children’s books in the “Their Side of the Story” collection (B&H) a lighthearted look on the animals' perspective of famous stories from the Bible and a children’s Christmas story, “Little Tree Found,” about a tree rescued by a Father and Son at Christmas time.`
    }, {
        name: 'STEVE MINICHIELLO',
        title: 'Producer / Director',
        description: `Steve Minichiello's television productions have won two Emmys and been nominated for seven others. Steve began his television career in Boston, where he wrote and produced for WBZ-TV, WGBH-TV (PBS) and WCVB-TV, including the renowned newsmagazine, Chronicle. His critically acclaimed documentary on youth and violence, “Lost Hopes, Shattered Dreams”, was broadcast on PBS. This production earned him an Emmy nomination, as well as a Silver Apple Award from the National Educational Video Festival. He also received an Emmy nomination, as well as an International Telly Award, National Association of Government Communicators Award and the San Francisco Film Festival Award for a 1995 PSA entitled, “Alone”. An animated PSA he wrote and produced on Fatherhood won an Emmy in 1997, and was an International Telly Award winner, as well as an ITS International Monitor Award finalist. In 1998 he was honored with three Emmy Nominations. In 1999 he won an Emmy for a spot entitled “Wish”. He received two Emmy Nominations for the year 2000, another in 2002 for the PSA, “Family”, and most recently for the Girl Scouts of America.` 
    }, {
        name: 'KELLY RUMMLER',
        title: 'Senior Video Editor',
        description: `Kelly is a busy mom, wife and the Founder and CEO of Rummler Creative, who has teamed up with Harper Media in Post Production. As a “Preditor” her creative insight comes from over 25 years of experience in powerful and inspirational storytelling. From fast paced commercials to stunning graphics, she has a passion for it all and can’t wait for the next storytelling adventure.
Her skills have been showcased in delivering campaigns for Disney Cruise Line, Adventures by Disney, Disney Vacation Club, Nassau Paradise Island: The Bahamas, Atlantis, Wilson, Ron Jon Surf Shops, Macaroni Grill and The Orlando Magic. She has produced and edited everything from high end commercial projects to reality TV programming.
Kelly is also uniquely skilled at delivering content which harnesses the essence of marketing initiatives and delivers powerful results in sales with short and long form Direct Response storytelling. Campaigns like, Secret Color with Demi Lovato, Oreck, Vermont Teddy Bear Company and Click-N-Clean; plus Children’s Direct Response products like, Beados, Cuddleuppets, FlipAZoos, FlipZee Girls, Happy Nappers, Hyperdisk, Janimals, Slushy Magic Max, tip of the iceberg!`
    }, {
        name: 'MICHAEL HARPER',
        title: 'Video Editor',
        description: `Michael is our resident technical wizard and youngest visual storyteller. His artistic approach to story, blended with a seemingly endless arsenal of digital tools, helps to keep our creative ideas collectively stronger, focused and challenged.
Michael’s training and roots are in information technology, applied artistically in creative graphic interface designing for clients such as Four Seasons Hotel and Resorts. He has programmed and applied program design configurations for a wide variety of technical applications through Johnson Controls.
As a video editor, Michael applies his experience and youth by questioning everything and accepting no limitations. His most recent work has been featured extensively on social media as a way for one of our larger corporate clients to connect with a brand-new audience and generate sales. Michael has delivered success for our clients from day one and continues to do so.
When Michael is not editing, he is enjoying time with his wife Kiara, their dogs Sophia and Biscuit (pictured here), and their cat Missy.`
    }
];

export default staff;