import React, { Component } from 'react';

//  App Styles
import '../styles/css/pages/what-we-do.css';

//  Data Imports
import data from '../data/services';

class WhatWeDo extends Component {
    _isMounted = false;

    state = {
        serviceOne: data[0],
        serviceTwo: data[1],
        serviceThree: data[2],
        index: 2
    }

    updateService = e => {
        const obj = {};
        if(this.state.index === data.length - 1) {
            obj[e.target.getAttribute('name')] = data[0];
            obj['index'] = 0;
            return this.setState(obj);
        } else {
            obj[e.target.getAttribute('name')] = data[this.state.index + 1];
            obj['index'] = this.state.index + 1;
            return this.setState(obj);
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.refs.serviceOne.classList.add('loopingScallingText');
        this.refs.serviceOne.addEventListener('animationiteration', this.updateService);
        setTimeout(() => {
            if(!this._isMounted) return;
            if(window.innerWidth < 450) return;
            this.refs.serviceTwo.classList.add('loopingScallingText');
            this.refs.serviceTwo.addEventListener('animationiteration', this.updateService);
        }, 1000);
        setTimeout(() => {
            if(!this._isMounted) return;
            if(window.innerWidth < 1200) return;
            this.refs.serviceThree.classList.add('loopingScallingText');
            this.refs.serviceThree.addEventListener('animationiteration', this.updateService);
        }, 2000);
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        return (
            <main className="about-page content-block-with-banner">
                <div className='banner'>
                    <img 
                        srcSet={`
                            ${require(`../images/fullscreens/thin_banner3-desktop.jpg`)} 1501w,
                            ${require(`../images/fullscreens/thin_banner3-tablet.jpg`)} 800w,
                            ${require(`../images/fullscreens/thin_banner3-mobile.jpg`)} 448w`}
                        sizes='
                            (min-width: 1200px) 1500px,
                            (min-width: 451px) and (max-width: 1299px) 800px,
                            (max-width: 450px) 448px'
                        alt='Banner'
                    />
                    <h3>- What We Do -</h3>
                    <div className='services'>
                        <p ref='serviceOne' name='serviceOne'>{this.state.serviceOne}</p>
                        <p ref='serviceTwo' name='serviceTwo'>{this.state.serviceTwo}</p>
                        <p className='desktop' ref='serviceThree' name='serviceThree'>{this.state.serviceThree}</p>
                    </div>
                </div>
                <p>Harper Media is an <span>AGILE</span> team of <span>HIGHLY EXPERIENCED CREATIVE</span> human resources who use <span>STATE-OF-THE-ART</span> technological tools to make ideas come to life. Our core four are master <span>STORYTELLERS, LEADERS</span> and <span>ORGANIZERS</span> with a measurable and proven success in their craft.</p>
                <p>The biggest difference between Harper Media and the larger, often pricier production companies is that we hand-pick a <span>TARGETED</span> freelance <span>CREATIVE TEAM</span> for every project that we do.  We match your very specific needs with very specific people.  These people are the <span>TOP CREATORS &#38; INNOVATORS</span> in the media world.</p>
                <p>We are not burdened with large employee overhead, and our costs reflect that. The team we use for your project will be <span>JUST RIGHT</span>.  Not too many, not too few.</p>
                <p>Let us show you why we are the <span>BEST CHOICE</span> for your business.</p>
            </main>
        );
    }
};

export default WhatWeDo;