import React from 'react';

const FeaturedClient = props => (
    <div className="featured-client">
        <img srcSet={`
                ${require(`../../images/clients/desktop/client-${props.index + 1}.jpg`)} 300w,
                ${require(`../../images/clients/tablet/client-${props.index + 1}.jpg`)} 300w,
                ${require(`../../images/clients/mobile/client-${props.index + 1}.jpg`)} 300w`}
            sizes='
                (min-width: 1200px) 300w,
                (min-width: 451px) and (max-width: 1299px) 300w,
                (max-width: 450px) 300w'
            alt="Featured Client"
        />
        <h4>{props.client.name}</h4>
        <p>{props.client.description}</p>
    </div>
);

export default FeaturedClient;