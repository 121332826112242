import React, { Component } from 'react';

class Clients extends Component {
    render() {
        return (
            <div className="clients">
                <h3>Clients</h3>
                <div className="slider">
                    {this.props.clients.map((client, index) => (
                        <div key={index}>
                            <img 
                                key={index} 
                                srcSet={`
                                    ${require(`../../images/clients/desktop/client-${index + 1}.jpg`)} 300w,
                                    ${require(`../../images/clients/tablet/client-${index + 1}.jpg`)} 300w,
                                    ${require(`../../images/clients/mobile/client-${index + 1}.jpg`)} 300w`}
                                sizes='
                                    (min-width: 1200px) 300px,
                                    (min-width: 451px) and (max-width: 1299px) 300px,
                                    (max-width: 450px) 300px'
                                onClick={e => this.props.handleClientChange(index, e)}
                                alt={client.name}
                            /> 
                            <svg key={index + 100} data-index={index} onClick={e => this.props.handleClientChange(index, e)} className="line">
                                <line className={this.props.index === index ? 'active' : undefined} x1="20%" y1="50%" x2="80%" y2="50%" />
                            </svg> 
                        </div>
                    ))}
                
                </div>
            </div>
        );
    }
    
};

export default Clients;