import React from 'react';

const ClosedMenu = props => (
    <svg 
        className={`closed-menu ${props.classes}`}
        onClick={e => props.data !== undefined ? props.onclickHandler(props.data, e) : props.onclickHandler(e)}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 800 675"
    >
        <title>Closed Menu</title>
        <line className="closed-menu-1" x1="15.52" y1="237.5" x2="650" y2="237.5"/>
        <line className="closed-menu-1" x1="15.52" y1="337.5" x2="650" y2="337.5"/>
        <line className="closed-menu-1" x1="15.52" y1="437.5" x2="650" y2="437.5"/>
    </svg>
);

export default ClosedMenu;