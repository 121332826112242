import React, { Component } from 'react';

//  App Compnents
import Player from '@vimeo/player';

//  App Styles
import '../styles/css/pages/home-page.css';

//  Data
import data from '../data/home-page';

class Home extends Component {
    _isMouted = false;
    state = {
        index: 0
    }

    componentDidMount = () => {
        this._isMouted = true;
        document.title = "Harper Media - Home";
        const quote = this.refs.quote;
        quote.addEventListener('animationiteration', e => {
            if(this.state.index === data.banners.length - 1) {
                return this.setState({
                    index: 0
                });
            } else {
                return this.setState({
                    index: this.state.index + 1
                });
            }
        });

        const iframe =  this.refs.iframe;
        const player = new Player(iframe);
        player.on('play', () => {
            setTimeout(() => {
                if(this._isMouted) document.querySelector('img').classList.add('fadeOut');
            }, 2000);
        });
    }
    componentWillUnmount = () => {
        this._isMouted = false;
    }

    render() {
        return (
            <main className="home-page">
                <div className="vimeo-wrapper">
                    <iframe
                        title="background_video" 
                        ref="iframe"
                        src="https://player.vimeo.com/video/326151038?title=0&byline=0&portrait=0&transparent=0&autoplay=1&controls=0&muted=1&loop=1"
                        frameBorder="0" >
                    </iframe>
                </div>
                <img 
                    srcSet={`
                        ${require(`../images/fullscreens/homepage_mobile.jpg`)} 448w,
                        ${require(`../images/fullscreens/homepage_tablet.jpg`)} 800w,
                        ${require(`../images/fullscreens/homepage_desktop.jpg`)} 1501w
                    `}
                    sizes='
                        (max-width: 450px) 448px,
                        (min-width: 451px) and (max-width: 1299px) 800px,
                        (min-width: 1200px) 1500px'
                    alt='Banner'
                />
                <div ref="quote" className="loopingScallingText">
                    <h4>{data.banners[this.state.index].quote}</h4>
                    <ul className="quoted">
                        <li><span>-</span>{data.banners[this.state.index].name}</li>
                        <li>{data.banners[this.state.index].position}</li>
                        <li>{data.banners[this.state.index].company}</li>
                    </ul>
                </div>
            </main>
        );
    }
}

export default Home;