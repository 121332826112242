import React from 'react';

const XIcon = props => (
    <svg 
        className={`x-icon ${props.classes}`}
        onClick={e => props.data ? props.onclickHandler(props.data, e) : props.onclickHandler(e)}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 800 675"
    >
        <title>X_Icon</title>
        <g>
            <circle className="x-icon-1" cx="400" cy="337.5" r="304.65"/>
            <g>
                <line className="x-icon-2" x1="272.72" y1="210.22" x2="527.28" y2="464.78"/>
                <line className="x-icon-2" x1="527.28" y1="210.22" x2="272.72" y2="464.78"/>
            </g>
        </g>
    </svg>
)

export default XIcon;