import React from 'react';

const ArrowIcon = props => (
    <svg 
        className={`arrow-icon ${props.classes}`}
        onClick={e => props.data !== undefined ? props.onclickHandler(props.data, e) : props.onclickHandler(e)}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 800 675"
    >
        <title>Arrow_Left</title>
        <path className="arrow-icon-1" d="M272.73,84.3,522.88,334.44a15,15,0,0,1,0,21.23L272.73,605.82"/>
    </svg>
);

export default ArrowIcon;