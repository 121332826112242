const whyHarperMedia = {
    quotes: [
        {
            quote: `"Hard working, detailed and creative - Harper Media brings their clients all of the things that they need for success, with world-class experience to back it up.  I've worked with Jason for many years, and he's been a big part of bringing ratings success to our networks"`,
            name: 'Niels Schuurmans',
            company: 'CMO/CCO Paramount Network/CMT/TV LAND'
        }, {
            quote: `“Jason Harper is exactly the kind of person you want to work with in this business. He’s cool under pressure, having done live TV with Jason for years I can say that for a fact! He’s an intellectual guy with an artistic soul and has the ability to create a work environment that is both innovative and approachable.”`,
            name: 'Jessica Holmes',
            company: 'KTLA 5 News - Los Angeles',
            position: 'News Anchor'
        }, {
            quote: `“Jason is a hard working, multi-tasking producer/director. He did 7 seasons of the hardest show on television - and didn’t age half as much as me.”`,
            name: 'Richard Barry',
            position: 'VP, Creative Director',
            company: 'Viacom International Media Networks'
        }, {
            quote: `“The Villages has worked with Jason (Harper Media) on a number of projects, both big and small, and could not be happier with the work he produces. He fundamental understands our goals and is able to connect with our customers like no one else. Which is why we are trusting him with our largest project in the last 10 years!”`,
            name: 'Ryan McCabe',
            position: 'Digital Marketing Strategist',
            company: 'The Villages'
        }, {
            quote: `“Jason Harper is without question one of the most talented writer/director/producers I have ever worked with. I had the pleasure of hosting Nickelodeon's "Slime Time Live" under Jason's leadership and the show was a huge hit, due in large part to his guidance. Jason is exceptionally creative, a problem solver and a total team player. If given the opportunity I would absolutely work with Jason again and I highly recommend collaborating with him on any project. He's the best!”`,
            name: 'Dave Aizer',
            position: 'TV Host, Writer, Producer'
        }, {
            quote: `"As a direct response marketer, we need to create advertising that produces measurable results.  We've worked with Jason and Harper Media for a number of years to create both TV ads and radio spots for our Vermont Teddy Bear and PajamaGram brands. Why? Because his expertise, wisdom and experienced eye have helped us produce those results."`,
            name: 'Ryan Jennings',
            position: 'Director, Mass Media & Catalog Marketing',
            company: 'The Vermont Teddy Bear Company'
        }, {
            quote: `“I've had the pleasure of working with Jason for the last 5 years. He has proven to be one of the most versatile TV professionals I've had the pleasure to work with. Jason can comfortable and aptly slide from field producer, to post producer, to director (and even camera), and do all of them at an extremely high level. Jason also works well within a team, and always finds a way to positively collaborate with the many diverse personalities of a production team. Jason is also very self-driven, and doesn't need a lot of hand-holding. But on the occasions he does get feedback or notes, he listens, never gets defensive, and then adjusts to deliver the best possible end product. As I said, I've enjoyed working with Jason, and look forward to collaborating with him again in the future.”`,
            name: 'Keith Allo',
            position: 'VP Programming & Original Productions',
            company: 'NBC Universal, Golf Channel'
        }
    ],
    clients: [
        {
            name: 'AE Network',
            description: 'Production for “60 Days In”, History Channel - “Food Tech”, and multiple documentary specials.'
        }, {
            name: 'Walt Disney Company',
            description: 'Full commercial ad and promotional campaigns, Production for Walt Disney Imagineering, Walt Disney Entertainment, Disney Institute, Marvel Universe Live.'
        },{
            name: 'Golf Channel',
            description: 'Production for “Altered Course”, “Big Break NFL”, “Big Break”, “In Play with Jimmy Roberts”, and “Feherty”.'
        }, {
            name: 'HGTV',
            description: 'Production for “My Yard Goes Disney”.'
        }, {
            name: 'Home Depot',
            description: 'Multiple national television ad campaigns.'
        }, {
            name: 'HSN',
            description: 'Production for Google.'
        }, {
            name: 'NBC Universal',
            description: 'Production for “The Biggest Loser, Olympics, Trolls, Jurassic World Live, and International documentaries.'
        }, {
            name: 'Nickelodeon',
            description: 'Full production for “Nick Arcade”, “Slimetime Live”, “Splat”, “Clarissa Explains it All”, “U-Pick Live”, “Taina”, On-air promos, and live specials.'
        }, {
            name: 'Olympic Channel',
            description: 'Lima Peru - Production for Olympic city announcement event (Paris 2024 & L.A. 2028), Rio 2016.'
        }, {
            name: 'Romanos Macaroni Grill',
            description: 'National television ad campaign.'
        }, {
            name: 'Vermont Teddy Bear Company',
            description: 'Multiple national television & radio ad campaigns.'
        }, {
            name: 'Viacom Networks',
            description: 'Production for VH1 - “Hogan Knows Best”, MTV - “Real World”, “The Ex and the Why”, and International promotional campaigns.'
        }, {
            name: 'Food Network',
            description: '“Home Made in America with Sunny Anderson”, “Tailgate Warriors with Guy Fieri”, and “Food Tech”.'
        }, {
            name: 'Publix',
            description: '"Production for commercial ads and "Aprons" Live"'
        }
    ]
};

export default whyHarperMedia;