import React, { Component } from 'react';

class BackgroundQuotes extends Component {
    state = {
        randomOne: Math.floor(Math.random() * this.props.quotes.length),
        randomTwo: Math.floor(Math.random() * this.props.quotes.length)
    }

    loopQuotes = quotes => {
        const children = quotes.children;
        let counter = 0;
        for(let a = 0; a < children.length; a++) {
            children[a].classList.remove('singleFadeScaleTextText');
        }
        const interval = setInterval(() => {
            if(counter === children.length) return clearInterval(interval);
            children[counter].classList.add('singleFadeScaleTextText');
            counter++;
        }, 1000);
    }

    componentDidMount = () => {
        if(window.innerWidth <= 450) return null;
        this.loopQuotes(this.refs.quotes);
        this.refs.quotes.children[this.refs.quotes.children.length - 1].addEventListener('animationend', e => {
            this.loopQuotes(this.refs.quotes);
            return this.setState({
                randomOne: Math.floor(Math.random() * this.props.quotes.length),
                randomTwo: Math.floor(Math.random() * this.props.quotes.length)
            });
        });
    }

    render() {
        return (
            <div ref="quotes" className="background-quotes not-mobile">
                <p className="top">{this.props.quotes[this.state.randomOne].quote.substring(0, 150)}..."</p>
                <ul className="top quoted">
                    <li><span>-</span>{this.props.quotes[this.state.randomOne].name}</li>
                    <li>{this.props.quotes[this.state.randomOne].position}</li>
                    <li>{this.props.quotes[this.state.randomOne].company}</li>
                </ul>
                <ul className="bottom quoted">
                    <li><span>-</span>{this.props.quotes[this.state.randomTwo].name}</li>
                    <li>{this.props.quotes[this.state.randomTwo].position}</li>
                    <li>{this.props.quotes[this.state.randomTwo].company}</li>
                </ul>
                <p className="bottom">{this.props.quotes[this.state.randomTwo].quote.substring(0, 150)}..."</p>
            </div>
        );
    }
};

export default BackgroundQuotes;