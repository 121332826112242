import React, { Component } from 'react';

//  Style Imports
import '../styles/css/pages/contact.css';

//  Email Services
import * as emailjs from 'emailjs-com';
const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


class Contact extends Component {
    state = {
        invalid: null,
        error: null,
        buttonText: 'Submit Request',
        status: null
    }

    handleSubmit = e => {
        e.preventDefault();
        e.persist();
        if(!e.target[0].value) return this.setState({invalid: 0});
        if(!e.target[1].value) return this.setState({invalid: 1});
        if(!re.test(String(e.target[1].value).toLowerCase())) return;
        e.target[4].setAttribute('disabled', true);
        const recipientParams = {
            name: e.target[0].value,
            email: e.target[1].value,
            reason: e.target[2].value,
            message: e.target[3].value,
        };
        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_RECIPIENT_TEMPLATE_ID, recipientParams, process.env.REACT_APP_USER_ID)
            .then(res => {
                emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_REPORTER_TEMPLATE_ID, recipientParams, process.env.REACT_APP_USER_ID)
                    .then(res => {
                        e.target[4].removeAttribute('disabled');
                        e.target.reset();
                        this.setState({buttonText: 'Request Sent', status: 'success', invalid: null});
                    })
                    .catch(e => {
                        e.target[4].removeAttribute('disabled');
                        console.log(e);
                        this.setState({buttonText: 'Request Failed', status: 'error', invalid: null});
                    });
            })
            .catch(e => {
                e.target[4].removeAttribute('disabled');
                this.setState({buttonText: 'Request Failed', status: 'error', invalid: null});
            });
        
        
    }
    componentDidMount = () => {
        document.title = "Harper Media - Contact";
    }

    render() {
        return(
            <main className="contact">
                <div className="banner">
                    <img 
                        srcSet={`
                            ${require(`../images/banners/Banner-3_desktop.jpg`)} 1501w,
                            ${require(`../images/banners/Banner-3_tablet.jpg`)} 800w,
                            ${require(`../images/banners/Banner-3_mobile.jpg`)} 448w`}
                        sizes='
                            (min-width: 1200px) 1500px,
                            (min-width: 451px) and (max-width: 1299px) 800px,
                            (max-width: 450px) 448px'
                        alt='Banner'
                    />
                </div>
                <form onSubmit={this.handleSubmit}>
                    <h3>Contact Us</h3>
                    <p>Name:</p>
                    <input className={this.state.invalid === 0 ? 'error': null} type="text"></input>
                    <p>Email:</p>
                    <input className={this.state.invalid === 1 ? 'error': null} type="email" ></input>
                    <p>Contact Reason:</p>
                    <select>
                        <option>Request a Quote</option>
                        <option>Request Additional Information</option>
                    </select>
                    <textarea></textarea>
                    <button className={this.state.status} type="submit">{this.state.buttonText}</button>
                </form>
                <div>
                    <h3>Contact Information</h3>
                    <p>Harper Media</p>
                    <p>@</p>
                    <p>NFocus Production Studios</p>
                    <p>1910 Harden Blvd - Suite 109 - Lakeland, FL 33803</p>
                </div>
            </main>
        );
    }
}

export default Contact;