import React from 'react';

const Logo = props => (
    <svg className="harper_media" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 745 300">
        <title>Harper_Media_Logo</title>
        <g data-name="Icon">
            <path className="harper_media-1" d="M145.39,37.37a1.9,1.9,0,0,0-1.88-1.91h-6.9a2.44,2.44,0,0,1-.48-.05c-3.14,0-6.28,0-9.42,0-4.14,0-4.36.23-4.36,4.47q0,57.94,0,115.91V271.13c0,5.43.17,5.6,5.41,5.6,4.5,0,9,0,13.48,0,3.77,0,4.07-.32,4.07-4.15q0-41.08,0-82.17a2.78,2.78,0,0,1-.09-.67V147.1a2.41,2.41,0,0,1,.1-.67q0-50.29,0-100.6c0-.93,0-1.68.08-2.29Z"/>
            <path className="harper_media-1" d="M236.31,35.42q-12.47,0-24.95,0c-3.52,0-3.74.21-3.21,3.57,2,12.8,4.06,25.58,6.07,38.37,1.73,11,3.37,22,5.14,32.95,2.12,13.16,4.38,26.3,6.5,39.46,1.7,10.6,3.26,21.22,4.92,31.82q2.85,18.19,5.77,36.37c.1.65.11,1.59.92,1.58,1.14,0,1.14-1.12,1.26-2,1.18-8.14,2.32-16.28,3.51-24.41,1.81-12.34,3.66-24.67,5.47-37,2.46-16.85,4.87-33.69,7.34-50.54,1.8-12.23,3.77-24.43,5.44-36.68,1-7.36,2.52-14.64,3.25-22,.26-2.64.8-5.33,1.33-8s-.35-3.57-2.94-3.54C253.52,35.47,244.92,35.42,236.31,35.42Z"/>
            <path className="harper_media-1" d="M35.52,88.5q0,24.41,0,48.81c0,3.83.11,4,3.83,4q18.07,0,36.14,0c3.49,0,3.81-.32,3.81-3.8q0-49.11,0-98.21c0-3.52-.32-3.84-3.77-3.85-12.14,0-24.28,0-36.42,0-2.75,0-3.65.95-3.64,3.72C35.57,55.56,35.52,72,35.52,88.5Z"/>
            <path className="harper_media-1" d="M79.31,228.74q0-21.93,0-43.87c0-3.89-.24-4.13-4-4.14H39.41c-3.72,0-3.89.18-3.89,3.94q0,38.21,0,76.4c0,4.06-.2,8.11,0,12.17.09,2.44.85,3.53,3.34,3.51q18.64-.08,37.28,0c2.83,0,3.26-1.49,3.25-3.86C79.28,258.18,79.31,243.46,79.31,228.74Z"/><path d="M182.41,105.46V222.54q0,25,0,50c0,3.92.27,4,4,4.26,7.21.6,14.4-.38,21.6-.08,3.49.15,3.53-.15,3-3.57-1.66-10.4-3.19-20.82-4.89-31.22-2.15-13.14-4.45-26.26-6.6-39.4-1.67-10.2-3.2-20.43-4.82-30.64q-3-19-6.12-38.07c-1.54-9.44-3.17-18.86-4.77-28.29-.12-.72.14-1.88-.84-1.89S182.27,104.75,182.41,105.46Z"/>
            <path className="harper_media-1" d="M272.48,195.81c-2.09,6.55-2.35,12.95-3.36,19.19-2,12.39-3.62,24.84-5.43,37.26-1,6.87-2.1,13.74-3.11,20.61-.48,3.24,0,3.8,3.19,3.86,2.2,0,4.39,0,6.59,0s2.92-1,2.89-3c-.06-3.58,0-7.16,0-10.74q0-31.07,0-62.13C273.23,199.33,273.55,197.76,272.48,195.81Z"/>
        </g>
        <g data-name="Text">
            <path className="harper_media-1" d="M314.1,36.09H332V82.44h19.19V36.09h17.89V149.94H351.18V98.71H332v51.23H314.1Z"/>
            <path className="harper_media-1" d="M406,36.09h24.24l18.54,113.85H430.87l-3.26-22.61v.33H407.29L404,149.94H387.44Zm19.52,76.12-8-56.27h-.32l-7.81,56.27Z"/>
            <path className="harper_media-1" d="M467.13,36.09h26.51q13.82,0,20.17,6.43t6.34,19.76v7q0,17.73-11.71,22.44V92a14,14,0,0,1,9.19,8q2.68,6,2.68,16.1v20a75.27,75.27,0,0,0,.33,7.89,19.85,19.85,0,0,0,1.62,5.94H504.05a25.74,25.74,0,0,1-1.3-5.21,77,77,0,0,1-.33-8.78V115.13q0-7.8-2.52-10.89t-8.7-3.09H485v48.79H467.13Zm24.4,48.79c3.57,0,6.26-.92,8.05-2.76s2.68-4.93,2.68-9.27V64.07q0-6.18-2.2-8.95t-6.91-2.76H485V84.88Z"/>
            <path className="harper_media-1" d="M542.43,36.09h26.35q13.34,0,20,7.16t6.67,21V75.45q0,13.83-6.67,21t-20,7.16h-8.46v46.35H542.43Zm26.35,51.23c2.92,0,5.12-.81,6.58-2.44s2.2-4.39,2.2-8.29V63.09c0-3.9-.73-6.67-2.2-8.29s-3.66-2.44-6.58-2.44h-8.46v35Z"/>
            <path className="harper_media-1" d="M615,36.09h48.79V52.36h-30.9V82.44h24.56V98.71H632.85v35h30.9v16.27H615Z"/>
            <path className="harper_media-1" d="M683.75,36.09h26.51q13.83,0,20.17,6.43t6.34,19.76v7q0,17.73-11.71,22.44V92a14,14,0,0,1,9.19,8q2.68,6,2.69,16.1v20a75.7,75.7,0,0,0,.32,7.89,19.87,19.87,0,0,0,1.63,5.94H720.67a26.43,26.43,0,0,1-1.3-5.21,77.74,77.74,0,0,1-.32-8.78V115.13q0-7.8-2.52-10.89t-8.71-3.09h-6.18v48.79H683.75Zm24.4,48.79q5.37,0,8.05-2.76t2.68-9.27V64.07q0-6.18-2.19-8.95t-6.91-2.76h-8.14V84.88Z"/>
            <path className="harper_media-1" d="M314.1,162.08h25.53L351,243.56h.32l11.38-81.48h25.54V275.92H371.34v-86.2H371l-13,86.2h-15l-13-86.2h-.33v86.2H314.1Z"/>
            <path className="harper_media-1" d="M430.87,162.08h48.79v16.26h-30.9v30.09h24.55v16.26H448.76v35h30.9v16.26H430.87Z"/>
            <path className="harper_media-1" d="M519.17,162.08H546.5q13.34,0,20,7.15t6.67,21v57.57q0,13.83-6.67,21t-20,7.16H519.17Zm27,97.58a8.62,8.62,0,0,0,6.75-2.61q2.36-2.6,2.36-8.45V189.4q0-5.85-2.36-8.46a8.65,8.65,0,0,0-6.75-2.6h-9.11v81.32Z"/>
            <path className="harper_media-1" d="M617.73,162.08h17.89V275.92H617.73Z"/><path d="M692.05,162.08h24.23l18.54,113.84H716.93l-3.25-22.61v.33H693.35l-3.25,22.28H673.51Zm19.52,76.11-8-56.27h-.33l-7.81,56.27Z"/>
        </g>
    </svg>
)

export default Logo;