import React from 'react';



const Footer = props => (
    <footer className={props.location.pathname === '/home' ? 'home-footer' : null}>
        <div></div>
        <a href="mailto:jason.harper@harpermedia.digital">Jason.Harper@HarperMedia.Digital</a>
        <a href="tel:1-310-691-0838">(310) 691-0838</a>
        <p>{`${String.fromCharCode('0169')} ${new Date().getFullYear()} Harper Media, Inc`}</p>
        <div></div>
    </footer>
);

export default Footer;