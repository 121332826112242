import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

//  App Components
import Header from './Header';
import Home from './Home';
import Staff from './Staff';
import WhatWeDo from './WhatWeDo';
import WhyHarperMedia from './WhyHarperMedia';
import WorkSamples from './WorkSamples';
import Contact from './Contact';
import Footer from './Footer';

class App extends Component {

    render() {
        return [
            <Route key="1" path='/'render={props => <Header location={props.location}/>}></Route>,
            <Switch key="2">
                <Route exact path='/'><Redirect to='/home'></Redirect></Route>
                <Route exact path='/home'><Home /></Route>
                <Route exact path='/about'><Staff /></Route>
                <Route exact path='/what-we-do'><WhatWeDo /></Route>
                <Route exact path='/why-harper-media'><WhyHarperMedia /></Route>
                <Route exact path='/our-work'><WorkSamples /></Route>
                <Route exact path='/contact'><Contact /></Route>
            </Switch>,
            <Route key='3' path='/'render={props => <Footer key="3" location={props.location}/>}></Route>,
        ];
    }
}

export default App;