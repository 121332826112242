import React, { Component } from 'react';

//  Component Import
import Quote from './why-harper-media/Quote';
import BackgroundQuotes from './why-harper-media/BackgroundQuotes';
import Clients from './why-harper-media/Clients';
import FeaturedClient from './why-harper-media/FeaturedClient.js';

//  Data Imports
import data from '../data/why-harper-media';

//  Style Import
import '../styles/css/pages/why-harper-media.css'

class WhyHarperMedia extends Component {
    state = {
        clientIndex: 0
    }

    handleClientChange = (index, e) => {
        return this.setState({clientIndex: index});
    }
    componentDidMount = () => {
        document.title = "Harper Media - Why Harper Media";
    }

    render() {
        return (
            <main className="why-harper-media">
                <div className="quote-container">
                    <Quote 
                        quotes={data.quotes}
                    />
                    <BackgroundQuotes 
                        quotes={data.quotes}
                    />
                </div>
                <Clients 
                    clients={data.clients}
                    index={this.state.clientIndex}
                    handleClientChange={this.handleClientChange}
                />
                <FeaturedClient 
                    index={this.state.clientIndex}
                    client={data.clients[this.state.clientIndex]}
                />
            </main>
        );
    }
}

export default WhyHarperMedia;